

import libUmum from '../../library/umum'


export default {
  data() {
    return {

      form : {
        jenispresensi : '',
        JenisStatusId : '',
        NIP : '',
        TglMulai : '',
        TglSelesai : '',
        biodata_gelar_belakang : '',
        biodata_gelar_depan : '',
        biodata_nama : '',
        createdAt : '',
        createdBy : '',
        fileRef : '',
        id : '',
        jamDatang : '',
        jamPulang : '',
        jenisKategori : '',
        jenisizin : '',
        jenisizin_uraian : '',
        jeniskategori_uraian : '',
        keterangan : '',
        notif_keterangan : '',
        lat : '',
        lng : '',
        status : '',
        unit_kerja : '',
        unit_kerja_uraian : '',
      },

      list_data : [],


      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,

      mdl_hapus : false,
      mdl_terima : false,
      mdl_lihat : false,

      alert: false,
      simpan1: false,
      LIB : libUmum,


    }
  },
  methods: {
    getView : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_VerifikasiPermohonan + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              jns_permohonan : 'izin',
              unit_kerja : this.form.unit_kerja
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.cek_load_data = false;
              console.log(res_data);
      });
    },
    addData : function(){
      fetch(this.$store.state.url.URL_VerifikasiPermohonan + "terima", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      })
      .then(res => res.json())
      .then(res_data => {
        this.Notify(res_data.ket, res_data.color, res_data.icon);
      });
    },

    removeData : function(){
      fetch(this.$store.state.url.URL_VerifikasiPermohonan + "kembalikan", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      })
      .then(res => res.json())
      .then(res_data => {
        this.Notify(res_data.ket, res_data.color, res_data.icon);
      });
    },

    selectData : function(data){
      this.form = {
        jenispresensi : data.jenispresensi,
        JenisStatusId : data.JenisStatusId,
        NIP : data.NIP,
        TglMulai : data.TglMulai,
        TglSelesai : data.TglSelesai,
        biodata_gelar_belakang : data.biodata_gelar_belakang,
        biodata_gelar_depan : data.biodata_gelar_depan,
        biodata_nama : data.biodata_nama,
        createdAt : data.createdAt,
        createdBy : data.createdBy,
        fileRef : data.fileRef,
        id : data.id,
        jamDatang : data.jamDatang,
        jamPulang : data.jamPulang,
        jenisKategori : data.jenisKategori,
        jenisizin : data.jenisizin,
        jeniskategori_uraian : data.jeniskategori_uraian,
        jenisizin_uraian : data.jenisizin_uraian,
        keterangan : data.keterangan,
        notif_keterangan : data.notif_keterangan,
        lat : data.lat,
        lng : data.lng,
        status : data.status,
        unit_kerja : data.unit_kerja,
        unit_kerja_uraian : data.unit_kerja_uraian,
      };


    },

    // ========================= TAMBAHAN =======================

      cari_data : function(){
          this.page_first = 1;
          this.getView();
      },
      Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        indicatorColor : function(data){
          if (data == 0) {
            return {
              bg : `bg-orange-2`,
              status : false
            }  
          }
          else if(data == 1){
            return {
              bg : `bg-blue-1`,
              status : false
            }
          }
          else if (data == 2){
            return {
              bg : `bg-red-2`,
              status : true
            }
          }

        },



    // ========================= TAMBAHAN =======================
  },

  mounted () {
    this.form.unit_kerja = this.$store.state.unit_kerja
    this.getView();
  },
}
